import { Component, input } from '@angular/core';
import { FileItem } from '@shared/components/form-elements/file-upload/models/file-item.model';

@Component({
  selector: 'pn-file-card',
  templateUrl: './file-card.component.html',
  styleUrl: './file-card.component.scss',
})
export class FileCardComponent {
  file = input.required<FileItem>();

  getFileIcon() {
    const extension = this.file().file.name.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'image';
      default:
        return 'file';
    }
  }
}
