import { Component, input, output } from '@angular/core';
import { FileItem } from '@shared/components/form-elements/file-upload/models/file-item.model';
import { FileUploadErrors } from '@shared/components/form-elements/file-upload/models/file-upload-errors.model';
import { FileUploadService } from '@shared/components/form-elements/file-upload/services/file-upload.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'pn-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent {
  uploadUrl = input.required<string>();
  deleteUrl = input<string | null>(null);
  multiple = input<boolean>(false);
  maxFiles = input<number | null>(null);
  allowedExtensions = input<string | null>(null);
  maxFileSize = input<number | null>(null);
  uploadSuccess = output<{ file: FileItem | null; files: FileItem[] }>();

  isUploading: boolean = false;
  files: FileItem[] = [];
  fileUploadErrors: FileUploadErrors = {
    maxFileSize: false,
    maxFiles: false,
    allowedExtensions: false,
  };

  constructor(private fileUploadService: FileUploadService) {}

  onFileSelected(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const selectedFiles = target.files as FileList;
    this.resetErrors();

    if (selectedFiles.length === 0) {
      return;
    }

    const multiple = this.multiple();
    const maxFiles = this.maxFiles();
    const allowedExtensions = this.allowedExtensions();
    const maxFileSize = this.maxFileSize();

    const filesToUpload = multiple
      ? Array.from(selectedFiles)
      : [selectedFiles[0]];

    if (
      multiple &&
      maxFiles &&
      this.files.length + filesToUpload.length > maxFiles
    ) {
      this.fileUploadErrors.maxFiles = true;
      return;
    }

    filesToUpload.forEach((file) => {
      if (maxFileSize && file.size > maxFileSize) {
        this.fileUploadErrors.maxFileSize = true;
      }

      if (allowedExtensions && !allowedExtensions.includes(file.type)) {
        this.fileUploadErrors.allowedExtensions = true;
      }

      if (this.hasErrors()) {
        return;
      }

      const fileItem: FileItem = {
        file,
        uploadProgress: 0,
        isUploading: true,
      };

      this.files.push(fileItem);

      this.uploadFile(fileItem);
    });

    target.value = '';
  }

  uploadFile(fileItem: FileItem) {
    const file = fileItem.file;

    this.fileUploadService.uploadFile(this.uploadUrl(), file).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          fileItem.uploadProgress = Math.round(
            (100 * event.loaded) / (event.total || 1)
          );
        } else if (event.type === HttpEventType.Response) {
          const response = event.body;
          fileItem.uploadedFile = {
            name: response?.name || file.name,
            url: response?.url,
          };
          fileItem.isUploading = false;
          fileItem.uploadProgress = 100;

          this.checkAllUploadsComplete();
        }
      },
      error: () => {
        fileItem.error = 'Произошла ошибка при загрузке файла';
        fileItem.isUploading = false;

        this.checkAllUploadsComplete();
      },
    });
  }

  checkAllUploadsComplete() {
    const uploading = this.files.some((fileItem) => fileItem.isUploading);
    if (!uploading) {
      this.isUploading = false;

      const hasErrors = this.files.some((fileItem) => fileItem.error);

      if (!hasErrors) {
        this.uploadSuccess.emit({
          file: null,
          files: this.getUploadedFiles(),
        });
      } else {
        console.error('error');
      }
    }
  }

  deleteFile(fileItem: FileItem) {
    const deleteUrl = this.deleteUrl();
    if (!deleteUrl || !fileItem.uploadedFile) {
      return;
    }

    this.fileUploadService.deleteFile(deleteUrl).subscribe({
      next: () => {
        this.files = this.files.filter((f) => f !== fileItem);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  isMaxFiles(): boolean {
    if (!this.multiple()) {
      return this.files.length > 0;
    } else {
      const maxFiles = this.maxFiles();
      if (!maxFiles) {
        return false;
      }
      return this.files.length >= maxFiles;
    }
  }

  getUploadedFiles(): FileItem[] {
    return this.files.filter((fileItem) => fileItem.uploadedFile);
  }

  resetErrors() {
    this.fileUploadErrors = {
      maxFileSize: false,
      maxFiles: false,
      allowedExtensions: false,
    };
  }

  hasErrors(): boolean {
    return (
      this.fileUploadErrors.maxFileSize ||
      this.fileUploadErrors.maxFiles ||
      this.fileUploadErrors.allowedExtensions
    );
  }
}
