/* src/app/_shared/components/form-elements/file-upload/components/file-upload/file-upload.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  display: block;
  position: relative;
  margin-top: 20px;
}
:host:first-child {
  margin-top: 0;
}
.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}
.file-list.empty {
  margin-bottom: 0;
}
.file-error {
  margin-bottom: 10px;
}
@media (width <= 575px) {
  pn-button {
    width: 100%;
  }
}
/*# sourceMappingURL=file-upload.component.css.map */
