import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileCardComponent } from './components/file-card/file-card.component';
import { ButtonComponent } from '@shared/components/base/button/button.component';
import { ErrorComponent } from '@shared/components/form-elements/error/error.component';

@NgModule({
  declarations: [FileUploadComponent, FileCardComponent],
  imports: [CommonModule, ButtonComponent, ErrorComponent, NgOptimizedImage],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
